export const sampleActionTypes = {
  GETSAMPLES_SUCCESS: "GETSAMPLES_SUCCESS"
};

export const userActionTypes = {
  GETCURRENTUSER_REQUEST: "GETCURRENTUSER_REQUEST",
  GETCURRENTUSER_SUCCESS: "GETCURRENTUSER_SUCCESS",
  GETCURRENTUSER_FAILURE: "GETCURRENTUSER_FAILURE",
  LOGOUT: "LOGOUT"
};
